<template>
  <div>
    <div>
      <el-divider content-position="left">标签下单台账</el-divider>
      <el-button type="success" size="mini" @click="handleExport('placeOrder','下单台账')" v-waves>导出下单台账</el-button><br><br>
      <el-table :data="labelInfoDetails" style="width: 100%" border id="placeOrder">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column width="80" label="表单拆分" align="center">
          <template #default="scope">
            <el-tag type="success">{{scope.row.split}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order.orderBatch" label="订单批次" min-width="120" align="center"/>
        <el-table-column prop="model.materialCode" label="物料代码" width="120" align="center"/>
        <el-table-column prop="labelApply.creatorName" label="申请人" width="100" align="center"/>
        <el-table-column width="80px" label="标签状态" align="center">
          <template #default="scope">
            <el-tag :type="showLabelStateFilter(scope.row)">{{scope.row.labelState?scope.row.labelState.type:''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="order.orderTime" label="下单时间" width="180" align="center"/>
        <el-table-column prop="model.name" label="型号" min-width="100" align="center"/>
        <el-table-column prop="model.unit" label="单位" width="80" align="center"/>
        <el-table-column label="类型" align="center" width="80px">
          <template  #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.model.type && scope.row.model.type.title || ''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
        <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
        <el-table-column prop="model.page" label="联数" width="50" align="center"/>
        <el-table-column prop="labelCount" label="防伪码标签（套）" width="80" align="center"/>
        <el-table-column prop="barcodeNumber" label="条形码标签总数（枚）" width="100" align="center">
          <template #default="scope">
              <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="startLabel" label="起始标签" width="220" align="center"/>
        <el-table-column prop="endLabel" label="结束标签" width="220" align="center"/>
      </el-table>
    </div>
    <div v-if="orderInfo.orderStatus.name==='RECEIPT'">
      <el-divider content-position="left">标签接收台账</el-divider>
       <el-button type="success" size="mini" @click="handleExport('receiptOrder','接收台账')" v-waves>导出接收台账</el-button><br><br>
      <el-table :data="labelDetails" style="width: 100%" border id="receiptOrder">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="order.receiptTime" label="接收时间" width="180" align="center"/>
        <el-table-column prop="order.orderBatch" label="订单批次" min-width="200" align="center"/>
        <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
        <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
        <el-table-column prop="model.page" label="联数" width="80" align="center" />
        <el-table-column prop="applyCount" label="防伪码标签（套）" width="80" align="center"/>
        <el-table-column prop="barcodeNumber" label="条形码标签总数（枚）" width="100" align="center">
         <template #default="scope">
              <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
          </template>
        </el-table-column>
         <!-- <el-table-column prop="productionRemark" label="生产备注" min-width="200" align="center"/> -->
      </el-table>
    </div>
    <div v-if="orderInfo.distributeState">
      <el-divider content-position="left">标签分发台账</el-divider>
       <el-button type="success" size="mini" @click="handleExport('acceptOrder','分发台账')" v-waves>导出分发台账</el-button><br><br>
      <el-table :data="labelDetails" style="width: 100%" border id="acceptOrder">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="distributeTime" label="分发时间" width="180" align="center"/>
        <el-table-column prop="order.orderBatch" label="订单批次" min-width="150" align="center"/>
        <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
        <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
        <el-table-column prop="model.page" label="联数" width="50" align="center"/>
        <el-table-column prop="applyCount" label="防伪码标签（套）" width="80" align="center"/>
        <el-table-column prop="barcodeNumber" label="条形码标签总数（枚）" width="100" align="center">
          <template #default="scope">
              <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="logisticsNumber" label="物流单号" min-width="180" align="center"/>
        <el-table-column prop="consignee" label="接收人" width="100" align="center"/>
        <el-table-column prop="acceptTime" label="接收时间" width="200" align="center"/>
        <!-- <el-table-column prop="distributeRemark" label="分发备注" min-width="150" align="center"/> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import standingBookInfoApi from '@/apis/standingBook/standingBookInfo'
// import FileSaver from 'file-saver'
// import XLSX from 'xlsx'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      orderId: this.id,
      labelInfoDetails: [],
      labelDetails: [],
      orderInfo: {
        orderStatus:{
          name: undefined,
          type: undefined
        },
        notAcceptManufacturerNum: undefined
      }
    }
  },
  created() {
    this.getLabelByOrderId()
    this.getLabelInfoByOrderId()
  },
  mounted(){
  },
  methods:{
    showLabelStateFilter(row){
      if(row.labelState && row.labelState.name === 'IN_EFFECT'){
        return 'success'
      }else{
        return 'danger'
      }
    },
    showFilter (row) {
      return row.model.type && row.model.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    getLabelInfoByOrderId(){
      if(this.orderId){
        standingBookInfoApi.getApplyLabelByOrderId(this.orderId).then(resp =>{
          if(resp.data){
            var labels = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
            this.labelInfoDetails = labels.filter(item => item.labelState.name==='IN_EFFECT')
          }
        })
      }
    },
    getLabelByOrderId(){
      if(this.orderId){
        standingBookInfoApi.getLabelByOrderId(this.orderId).then(resp =>{
          if(resp.data){
            var labels = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.applyCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
            this.labelDetails = labels.filter(item => item.labelState.name==='IN_EFFECT')
            this.orderInfo = resp.data[0].order
          }
        })
      }
    },
    handleExport(tableName,name){
      //从表生成工作簿对象 
      // var wb = XLSX.utils.table_to_book(document.querySelector('#' + tableName))
      // 获取二进制字符串作为输出
      // var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
      // try {
      //   FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), name + '.xlsx')
      // } catch (e) {
      //   if (typeof console !== 'undefined'){
      //   }
      // }
      // return wbout
      var exportVo = {
        orderId: this.orderId,
        exportType: tableName
      }
      standingBookInfoApi.exportBook(exportVo).then(resp=>{
        saveAs(resp.data, name + `.xlsx`)
      }).catch(res => {
        this.$message.error("无法下载")
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>