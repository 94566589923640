import api from '../api'
/**
 * 标签台账
 */
export class StandingBookInfoApi extends api {
  exportBook(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/exportBook`,
      responseType: 'blob',
      method: 'post',
      data
    })
  }
  getLabelByOrderId (orderId) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/getLabelByOrderId/${orderId}`,
        method: 'get'
    })
  }
  getApplyLabelByOrderId (orderId) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/getApplyLabelByOrderId/${orderId}`,
        method: 'get'
    })
  }
}

export default new StandingBookInfoApi('standingBookInfo')